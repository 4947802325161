import { useEffect, useRef, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { postLogNCrash } from 'api/nhn';
import PopupAD from './PopupAD';
import {
  kioskStatusAtom,
  languageAtom,
  loginInfoAtom,
} from 'store/globalStateAtom';

import { SupportLang } from 'types/language.type';
import { ADIntervalTime, ADPopupHoldingTime } from 'constants/ad.const';

type BannerType = {
  footerUrl: string;
  popUpUrl: string;
  advertisementDetailId: number | null;
};

type LanguageAds = {
  [key in SupportLang]?: BannerType[];
};

type BannerADProps = {
  defaultLanguage?: SupportLang;
};

function BannerAD({ defaultLanguage }: BannerADProps) {
  const kioskStatus = useAtomValue(kioskStatusAtom);
  const bannerList: LanguageAds =
    (kioskStatus?.adsUrls?.mainUrl as LanguageAds) ?? {};
  const [filteredBanners, setFilteredBanners] = useState<BannerType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const timeoutRef = useRef<number | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const isOpenRef = useRef(isOpen);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [lang] = useAtom(languageAtom);

  const handleButtonClick = (adId: number | null) => {
    setIsOpen(true);
    postLogNCrash(
      `센터 키오스크 광고 클릭 - 광고id:${adId} name:${loginInfo.name} controlCode:${loginInfo.controlCode}`,
    );
    isOpenRef.current = true;

    timeoutRef.current = window.setTimeout(() => {
      handleClose();
    }, ADPopupHoldingTime);
  };

  const handleClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsRemoving(true);

    timeoutRef.current = window.setTimeout(() => {
      setIsOpen(false);
      isOpenRef.current = false;
      setIsRemoving(false);
      timeoutRef.current = null;
    }, 500);
  };

  useEffect(() => {
    const selectedLang = defaultLanguage ?? lang;
    setFilteredBanners(bannerList[selectedLang as SupportLang] ?? []);
  }, [bannerList, lang, defaultLanguage]);

  useEffect(() => {
    if (filteredBanners.length === 0) return;

    const startRotation = () => {
      if (!isOpenRef.current) {
        setCurrentBannerIndex(
          (prevIndex) => (prevIndex + 1) % filteredBanners.length,
        );
      }
    };

    intervalRef.current = setInterval(startRotation, ADIntervalTime);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [filteredBanners]);

  return (
    <>
      {filteredBanners?.map((bannerInfo, index) => {
        if (currentBannerIndex === index) {
          return (
            <div key={bannerInfo.popUpUrl}>
              <button
                className={`fixed bottom-0 left-0 right-0 z-10 bg-transparent border-none cursor-pointer`}
                onClick={() =>
                  handleButtonClick(bannerInfo.advertisementDetailId)
                }
              >
                <img src={bannerInfo?.footerUrl} alt='배너광고' />
              </button>
              {isOpen && (
                <div
                  className={`fixed top-0 bottom-0 left-0 right-0 z-10 w-screen bg-black bg-opacity-50 ${
                    isRemoving ? 'animate-fadeout' : 'animate-fadein'
                  }`}
                >
                  <div
                    className={`w-screen fixed bottom-0 left-0 right-0 top-0 flex justify-center items-end ${
                      isRemoving ? 'animate-slideDown' : 'animate-slideUp'
                    }`}
                    onClick={handleClose}
                  >
                    <PopupAD
                      popUpUrl={bannerInfo?.popUpUrl}
                      adID={bannerInfo.advertisementDetailId}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }
        return '';
      })}
    </>
  );
}

export default BannerAD;
