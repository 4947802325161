import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom, useSetAtom } from 'jotai';

import Layout from 'components/common/Layout';
import QwertyKeypad from 'components/common/QwertyKeypad';
import PrivacyConsent from 'components/PassportCertification/PrivacyConsent';
import PrivacyConsentModal from 'components/PassportCertification/PrivacyConsentModal';
import passport_cut from 'images/PassportCertification/passport_cut.png';
import progressKo from 'images/PassportCertification/progress_ko_1.png';
import progressEn from 'images/PassportCertification/progress_en_1.png';
import progressCh from 'images/PassportCertification/progress_ch_1.png';
import progressJp from 'images/PassportCertification/progress_jp_1.png';
import arrowLeft from 'images/common/chevron_left_black.png';
import arrowRight from 'images/common/chevron_right_white.png';
import useGetRefundInfo from 'hooks/useGetRefundInfo';
import {
  languageAtom,
  loginInfoAtom,
  privacyConsentInfoAtom,
  privacyConsentModalInfoAtom,
  withdrawalInfoAtom,
} from 'store/globalStateAtom';
import useLogging from 'hooks/useLogging';
import { LANGUAGE } from 'types/language.type';

function PassportCertification() {
  const { t } = useTranslation();
  const [lang] = useAtom(languageAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [, setWithdrawalInfo] = useAtom(withdrawalInfoAtom);
  const [privacyConsentInfo, setPrivacyConsentInfo] = useAtom(
    privacyConsentInfoAtom,
  );
  const setPrivacyConsentModalInfo = useSetAtom(privacyConsentModalInfoAtom);
  const [passportNumber, setPassportNumber] = useState('');
  const navigate = useNavigate();
  const { mutate, isLoading } = useGetRefundInfo();
  const { mutate: logOnServer } = useLogging();

  const formatInputValue = (value: string) => value.trim().toUpperCase();

  const log = (data: string) => {
    logOnServer({ controlCode: loginInfo?.controlCode, data });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const formattedValue = formatInputValue(e.target.value);
    setPassportNumber(formattedValue);
  };
  const goBack = () => {
    navigate('/language-selection');
  };
  const goNext = async () => {
    // 동기를 사용한 이유 -> withdrawalInfo를 바로 사용하기 위해
    await setWithdrawalInfo({
      passportNumber: passportNumber,
      totalRefund: 0,
      withdrawal: 0,
      notwithdrawal: 0,
    });

    log(
      `키오스크 여권 조회 :: - 키오스크 이름: ${loginInfo?.name}, - passportNumber: ${passportNumber}`,
    );

    // 여권정보를 입력할 때 방출관련 정보 초기화
    mutate({ passportNumber, kioskId: loginInfo?.kioskId });
  };

  useEffect(() => {
    setPrivacyConsentInfo({ isPrivacyConsent: false, privacyAgreedTime: '' });
    setPrivacyConsentModalInfo(null);
    const timer = setTimeout(() => {
      navigate('/promotion');
    }, 60000 * 3);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout isLoading={isLoading} isSetting>
      <img
        src={
          lang === LANGUAGE.EN
            ? progressEn
            : lang === LANGUAGE.JA
            ? progressJp
            : lang === LANGUAGE.ZH
            ? progressCh
            : progressKo
        }
        height={90}
        width={960}
        alt='progress'
        className='w-full mb-[52px]'
      />
      <div className='w-full rounded-20'>
        <img
          src={passport_cut}
          height={533}
          width={910}
          alt='passport'
          className='mx-auto'
        />
      </div>
      <h2 className='font-bold text-head-01 text-mono-80'>
        {t('enter_passport')}
      </h2>
      <input
        className='py-8 px-12 rounded-20 placeholder:text-[42px] text-[42px] w-full mt-[24px] outline outline-4 outline-blue-60'
        placeholder='Passport NO.'
        onChange={onChange}
        value={passportNumber}
      />
      <PrivacyConsent />
      <div className='flex justify-between'>
        <button
          onClick={goBack}
          className='flex items-center py-6 font-semibold bg-white text-subHead-01 px-28 rounded-100 text-mono-80 mt-36 shadow-default'
        >
          <img src={arrowLeft} width={36} height={36} alt='arrow_left' />
          {t('prev_step')}
        </button>
        <button
          onClick={goNext}
          disabled={!passportNumber || !privacyConsentInfo.isPrivacyConsent}
          className='flex items-center py-6 font-semibold text-white bg-blue-100 text-subHead-01 disabled:bg-blue-20 active:bg-blue-150 px-28 rounded-100 mt-36 shadow-default'
        >
          {t('next_step')}
          <img src={arrowRight} width={36} height={36} alt='arrow_right' />
        </button>
      </div>
      <QwertyKeypad setInput={setPassportNumber} containerStyle='mt-4' />
      <PrivacyConsentModal />
    </Layout>
  );
}

export default PassportCertification;
