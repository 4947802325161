import { useAtom } from 'jotai';

import { privacyConsentModalInfoAtom } from 'store/globalStateAtom';

function PrivacyConsentModal() {
  const [privacyConsentModalInfo] = useAtom(privacyConsentModalInfoAtom);

  const onClickBtn = () => {
    privacyConsentModalInfo?.btnCallback &&
      privacyConsentModalInfo.btnCallback();
  };

  const handleContextMenu: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
  };

  const parseDescription = (description: string) => {
    const lines = description.split('\n');
    let numberCounter = 1;

    return lines.map((line, index) => {
      if (line.includes('(number)')) {
        return (
          <p
            key={index}
            className='text-subHead-01 mt-8 text-[#777777] whitespace-pre-wrap text-left font-normal'
          >
            {numberCounter++}. {line.replace('(number)', '')}
          </p>
        );
      } else if (line.includes('(dot)')) {
        return (
          <ul className='pl-6'>
            <li
              key={index}
              className='list-disc text-subHead-01 text-[#777777] text-left font-normal whitespace-pre-wrap detail-caution-list'
            >
              {line.replace('(dot)', '')}
            </li>
          </ul>
        );
      }
    });
  };

  return (
    <>
      {privacyConsentModalInfo && (
        <div
          onContextMenu={handleContextMenu}
          className='fixed z-50 w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-black/50'
        >
          <div className='max-w-[900px] bg-white rounded-20 flex-col items-center py-[60px] px-[40px] text-center'>
            <h2 className='text-head-01 font-bold text-mono-80 whitespace-pre-wrap'>
              {privacyConsentModalInfo.title}
            </h2>
            <div className='mt-6'>
              {parseDescription(privacyConsentModalInfo.description)}
            </div>
            <div className='flex justify-center'>
              <button
                className='mt-[60px] bg-[#246CF6] text-white active:bg-[#246CF6] p-[24px] flex-1 font-semibold text-[36px] rounded-[8px]'
                onClick={onClickBtn}
              >
                {privacyConsentModalInfo.btnText}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PrivacyConsentModal;
