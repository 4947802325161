export const LANGUAGE = {
  EN: 'en',
  KO: 'ko',
  ZH: 'zh',
  JA: 'ja',
} as const;

type Union<T> = T[keyof T];

export type SupportLang = Union<typeof LANGUAGE>;
