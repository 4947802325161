import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import useUpdateKioskStatus from 'hooks/useUpdateKioskStatus';
import useLogging from 'hooks/useLogging';
import connectPort from 'images/common/connect_port_guide.png';
import {
  adminLoginInfoAtom,
  billTypeAtom,
  CountInfo,
  emissionKioskStatusAtom,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
  moneyNotWithdrawnAtom,
  privacyConsentInfoAtom,
  refundInfoAtom,
  withdrawalInfoAtom,
} from 'store/globalStateAtom';
import {
  calculateBC,
  calculateFCC,
  getIsLackOfCash,
  to8BitBinary,
} from 'utils';
import useCompleteRefunds from 'hooks/useCompleteRefunds';
import { Commands, Errors } from 'constants/command.const';

function useSerialCommunication() {
  const location = useLocation();

  const { t } = useTranslation();
  const STX = 0xfe;
  const { mutate } = useUpdateKioskStatus();
  const { isLoading: refundCompleteLoading, mutate: completeRefunds } =
    useCompleteRefunds();
  const { mutate: logOnServer } = useLogging();
  const navigate = useNavigate();

  const [moneyNotWithdrawn, setMoneyNotWithdrawn] = useAtom(
    moneyNotWithdrawnAtom,
  );
  const [port, setPort] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [kioskStatus, setKioskStatus] = useAtom(kioskStatusAtom);
  const [, setAdminLoginInfo] = useAtom(adminLoginInfoAtom);
  const [refundInfo, setRefundInfo] = useAtom(refundInfoAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [billType] = useAtom(billTypeAtom);
  const [withdrawalInfo] = useAtom(withdrawalInfoAtom);
  const [emissionKioskStatus, setEmissionKioskStatus] = useAtom(
    emissionKioskStatusAtom,
  );
  const [privacyConsentInfo, setPrivacyConsentInfo] = useAtom(
    privacyConsentInfoAtom,
  );

  // * 아래 useRef 사용 이유 *
  //- processValue 함수가 내부 함수여서 외부 상태들의 최신화된값을 가져오지 못함
  //- 내부함수에서는 ref를 통해서만 최신값을 가져올 수 있어서 useRef 사용
  const currentMoneyNotWithdrawnRef = useRef(moneyNotWithdrawn);
  const currentKioskStatusRef = useRef(kioskStatus);
  const currentLoginInfoRef = useRef(loginInfo);
  const resetDefaultInquiryCountRef = useRef(0);
  const emitDefaultInquiryCountRef = useRef(0);
  const refundInfoRef = useRef(refundInfo);
  const currentCMDRef = useRef<'RESET' | 'EMIT' | null>();
  const currentLocationRef = useRef<string>('');
  const errorDetectionStatusRef = useRef({ bd1: 0, bd2: 0, bd3: 0, hp1: 0 }); // 최초 에러 확인 유무 | 0: 정상, 1: 최초에러 감지, 2: 상세 에러상태 확인
  const withdrawalTargetCountRef = useRef({
    bd1Count: 0,
    bd2Count: 0,
    bd3Count: 0,
    hp1Count: 0,
  });
  const withdrawalInfoRef = useRef(withdrawalInfo);
  const emissionKioskStatusRef = useRef(emissionKioskStatus); // 교차방출 상태
  const privacyConsentInfoRef = useRef(privacyConsentInfo);

  const log = (data: string) => {
    logOnServer({
      controlCode: loginInfo?.controlCode,
      data,
    });
  };

  useEffect(() => {
    privacyConsentInfoRef.current = privacyConsentInfo;
  }, [privacyConsentInfo]);

  const goRefundComplete = (isWithdrawalNotCompleted?: boolean) => {
    console.log('미방출 금액', currentMoneyNotWithdrawnRef);
    emitDefaultInquiryCountRef.current = 0;

    const controlCode = currentLoginInfoRef.current.controlCode;
    const kioskName = currentLoginInfoRef.current.name;
    const passportNumber = withdrawalInfoRef.current.passportNumber;
    const bd1CountWithdrawn =
      refundInfoRef.current.bd1Count -
      currentMoneyNotWithdrawnRef.current.bd1Count;
    const bd2CountWithdrawn =
      refundInfoRef.current.bd2Count -
      currentMoneyNotWithdrawnRef.current.bd2Count;
    const bd3CountWithdrawn =
      refundInfoRef.current.bd3Count -
      currentMoneyNotWithdrawnRef.current.bd3Count;
    const hp1CountWithdrawn =
      refundInfoRef.current.hp1Count -
      currentMoneyNotWithdrawnRef.current.hp1Count;

    log(`환급액 방출 ${isWithdrawalNotCompleted ? '실패' : '성공'}:: ${
      isWithdrawalNotCompleted ? '이메일 입력' : '완료'
    } 페이지로 이동 - 키오스크 이름: ${kioskName}, controlCode: ${controlCode}, - passportNumber: ${passportNumber}, 
      \n- 방출 정보: bd1Count:${bd1CountWithdrawn}, bd2Count:${bd2CountWithdrawn}, bd3Count:${bd3CountWithdrawn}, hp1Count:${hp1CountWithdrawn}, 
      \n- 미방출 금액: bd1Count:${
        currentMoneyNotWithdrawnRef.current.bd1Count
      }, bd2Count:${currentMoneyNotWithdrawnRef.current.bd2Count}, bd3Count:${
      currentMoneyNotWithdrawnRef.current.bd3Count
    }, hp1Count:${currentMoneyNotWithdrawnRef.current.hp1Count}`);

    /** 환급 완료 후 방출기 상태 변경 */
    const { bd2Error, bd3Error, bd2TotalCount, bd3TotalCount } =
      currentKioskStatusRef.current;

    setEmissionKioskStatus({
      bd2Error,
      bd3Error,
      bd2TotalCount,
      bd3TotalCount,
    });

    if (isWithdrawalNotCompleted) {
      completeRefunds({
        controlCode,
        isWithdrawalNotCompleted: true,
        ...refundInfoRef.current,
        hp1Count: hp1CountWithdrawn,
        bd1Count: bd1CountWithdrawn,
        bd2Count: bd2CountWithdrawn,
        bd3Count: bd3CountWithdrawn,
        privacyAgreedTime: privacyConsentInfoRef.current?.privacyAgreedTime,
      });
    } else {
      completeRefunds({
        controlCode,
        ...refundInfoRef.current,
        privacyAgreedTime: privacyConsentInfoRef.current?.privacyAgreedTime,
      });
    }

    setPrivacyConsentInfo({ isPrivacyConsent: false, privacyAgreedTime: '' });
  };
  useEffect(() => {
    currentKioskStatusRef.current = kioskStatus;
  }, [kioskStatus]);

  useEffect(() => {
    currentLoginInfoRef.current = loginInfo;
  }, [loginInfo]);

  useEffect(() => {
    currentMoneyNotWithdrawnRef.current = moneyNotWithdrawn;
  }, [moneyNotWithdrawn]);

  useEffect(() => {
    refundInfoRef.current = refundInfo;
  }, [refundInfo]);

  useEffect(() => {
    withdrawalInfoRef.current = withdrawalInfo;
  }, [withdrawalInfo]);

  useEffect(() => {
    emissionKioskStatusRef.current = emissionKioskStatus;
  }, [emissionKioskStatus]);

  useEffect(() => {
    currentLocationRef.current = location.pathname;
  }, [location.pathname]);

  const openInitializationModal = () => {
    const isFirstSerialConnection = getIsFistSerialConnection();
    setModalInfo({
      title: '키오스크 기기 연결을 허용해주세요.',
      pointColorText: isFirstSerialConnection
        ? undefined
        : 'スタッフでお問い合わせください。请咨询职员。Please ask for assistance. ',
      description: `${
        isFirstSerialConnection
          ? '키오스크를 사용하기 위해 연결이 필요합니다.'
          : '페이지 새로고침이 감지되어 키오스크 연결이 끊어졌습니다.'
      }\n아래 사진을 참고해 연결을 진행해주세요.`,
      btnText: '확인',
      icon: 'CHECK',
      imageUrl: connectPort,
      btnCallback: initialize,
    });
  };
  const getIsFistSerialConnection = () => {
    const isFirstSerialConnection = localStorage.getItem(
      'isFirstSerialConnection',
    );
    if (!isFirstSerialConnection) {
      localStorage.setItem('isFirstSerialConnection', 'NO');
      return true;
    } else {
      return false;
    }
  };
  const initialize = async () => {
    try {
      //@ts-ignore
      const port = await navigator.serial.requestPort();
      log('포트 연결 성공');
      setPort(port);
      await port.open({ baudRate: 9600 });
      log('포트 열기 성공');
    } catch (err) {
      setModalInfo({
        title: '웹 사이트 연결을 확인해주세요.',
        description: `이미 키오스크 연결이 완료된 페이지가 실행 중입니다.\n현재 페이지를 닫고 이전에 실행 중인 페이지를 사용해주세요.`,
        btnText: '닫기',
        preventDefault: true,
        btnCallback: openInitializationModal,
        icon: 'ALERT',
      });
      log('포트 열기 실패');
    }
  };

  /** ** 명령어 전송 ** */
  async function sendCommandToSerialPort(commandBuffer: Uint8Array) {
    log(`요청 전송: ${commandBuffer}`);

    //@ts-ignore
    const writer = await port.writable.getWriter();
    await writer.write(commandBuffer);
    await writer.releaseLock();
  }
  /** ** 현금 방출 ** */
  async function emitBills(countInfo: CountInfo) {
    setIsLoading(true);
    const { bd1ErrorCode, bd2ErrorCode, bd3ErrorCode, hp1Error } =
      currentKioskStatusRef.current;

    if (bd2ErrorCode && bd3ErrorCode) {
      setIsLoading(false);
      return navigate('/notwithdrawal');
    }

    currentCMDRef.current = 'EMIT';
    // 패킷 정보
    const CMD = 0x12;
    const BC = calculateBC(CMD);

    //방출 목표 수량 저장
    withdrawalTargetCountRef.current = countInfo;

    const { hp1Count, bd1Count, bd2Count, bd3Count } = countInfo;
    // 방출할 금액보다 남아있는 잔량이 적을 경우 notwithdrawal 페이지로 이동
    if (getIsLackOfCash({ billType, kioskStatus, countInfo })) {
      setIsLoading(false);
      setModalInfo({
        title: t('error_cash_title'),
        description: t('error_cash_desc'),
        btnText: t('modal_confirm'),
        btnCallback: () => {
          navigate('/notwithdrawal');
        },
        icon: 'CHECK',
      });
      return;
    }

    // 데이터 필드 설정
    const dataArray = [
      hp1Count,
      0x00,
      bd1Count,
      bd2Count,
      bd3Count,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandPacket = new Uint8Array([...packetExcludingFcc, fcc]);

    // 키오스크 보드로 패킷 전송
    try {
      await sendCommandToSerialPort(commandPacket);
    } catch (err) {
      //응답
      console.error(err);
    }
  }
  /**** 기본 상태 조회 or 에러 조회 ** */
  const fetchKioskStatus = async (type: 'DEFAULT' | 'ERROR' = 'DEFAULT') => {
    const CMD = Commands.CMD_CHECK;
    const BC = calculateBC(CMD);

    // 데이터 필드 설정
    const dataArray = [
      type === 'DEFAULT' ? 0b00000000 : 0b00001000,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      await sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      console.error(err);
    }
  };
  /**** BD1, BD2, BD3, HP1 리셋 ** */
  const resetKiosk = async () => {
    setIsLoading(true);
    currentCMDRef.current = 'RESET';
    // 패킷 정보
    const CMD = Commands.CMD_CONTROL;
    const BC = calculateBC(CMD);

    // 데이터 필드 설정
    const dataArray = [
      0x00, 0b00001111, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      setIsLoading(false);
      console.error(err);
    }
  };

  const handleResetResponse = (
    hp1Error: boolean,
    bd1Error: boolean,
    bd2Error: boolean,
    bd3Error: boolean,
  ) => {
    resetDefaultInquiryCountRef.current++;
    if (resetDefaultInquiryCountRef.current === 1) {
      if (hp1Error || bd1Error || bd2Error || bd3Error) {
        log('리셋시 에러 존재(2회 기본 조회 응답 중 1회) - break');
        return false;
      } else {
        resetDefaultInquiryCountRef.current = 0;
        errorDetectionStatusRef.current = { bd1: 0, bd2: 0, bd3: 0, hp1: 0 };
        log('리셋시 에러 없음(1회 기본 조회 응답 중 1회) - 응답 처리');
      }
    } else {
      resetDefaultInquiryCountRef.current = 0;
      errorDetectionStatusRef.current = { bd1: 0, bd2: 0, bd3: 0, hp1: 0 };
      log('리셋시 에러 존재(2회 기본 조회 응답 중 2회) - 응답 처리');
    }
    return true;
  };

  const handleEmitResponse = () => {
    emitDefaultInquiryCountRef.current++;
    log('방출(기본 조회 응답 1회) - 응답 처리');
    if (emitDefaultInquiryCountRef.current === 2) {
      log('방출시 에러 없음(2회 기본 조회 응답 중 2회) - 초기화 + break');
      emitDefaultInquiryCountRef.current = 0;
      return false;
    }
    return true;
  };

  const updateKioskStatus = (
    countArr: (number | number[])[],
    hp1Error: boolean,
    bd1Error: boolean,
    bd2Error: boolean,
    bd3Error: boolean,
  ) => {
    const hp1Count = countArr[6] as number;
    const bd1Count = countArr[8] as number;
    const bd2Count = countArr[9] as number;
    const bd3Count = countArr[10] as number;

    const newKioskStatus =
      currentCMDRef.current === 'EMIT'
        ? {
            ...currentKioskStatusRef.current,
            hp1TotalCount:
              currentKioskStatusRef.current.hp1TotalCount - hp1Count,
            bd1TotalCount:
              currentKioskStatusRef.current.bd1TotalCount - bd1Count,
            bd2TotalCount:
              currentKioskStatusRef.current.bd2TotalCount - bd2Count,
            bd3TotalCount:
              currentKioskStatusRef.current.bd3TotalCount - bd3Count,
            hp1Error,
            bd1Error,
            bd2Error,
            bd3Error,
          }
        : {
            ...currentKioskStatusRef.current,
            hp1Error,
            bd1Error,
            bd2Error,
            bd3Error,
            bd1ErrorCode: bd1Error
              ? currentKioskStatusRef.current.bd1ErrorCode
              : 0,
            bd2ErrorCode: bd2Error
              ? currentKioskStatusRef.current.bd2ErrorCode
              : 0,
            bd3ErrorCode: bd3Error
              ? currentKioskStatusRef.current.bd3ErrorCode
              : 0,
          };

    setKioskStatus(newKioskStatus);
    currentKioskStatusRef.current = newKioskStatus;

    mutate({
      type: 'WITHDRAWAL',
      controlCode: currentLoginInfoRef.current.controlCode,
      ...newKioskStatus,
    });

    if (currentCMDRef.current === 'EMIT') {
      const newMoneyWithDrawn = {
        hp1Count: withdrawalTargetCountRef.current.hp1Count - hp1Count,
        bd1Count: withdrawalTargetCountRef.current.bd1Count - bd1Count,
        bd2Count: withdrawalTargetCountRef.current.bd2Count - bd2Count,
        bd3Count: withdrawalTargetCountRef.current.bd3Count - bd3Count,
      };
      setMoneyNotWithdrawn(newMoneyWithDrawn);
      currentMoneyNotWithdrawnRef.current = newMoneyWithDrawn;
      log(
        `- 키오스크 이름: ${currentLoginInfoRef.current.name}, controlCode: ${currentLoginInfoRef.current.controlCode}, - passportNumber: ${withdrawalInfoRef.current.passportNumber}, 미방출 수량: bd1Count:${newMoneyWithDrawn.bd1Count}, bd2Count:${newMoneyWithDrawn.bd2Count}, bd3Count:${newMoneyWithDrawn.bd3Count}, hp1Count:${newMoneyWithDrawn.hp1Count}`,
      );
    }
  };

  const handleKioskErrors = (
    hp1Error: boolean,
    bd1Error: boolean,
    bd2Error: boolean,
    bd3Error: boolean,
  ) => {
    const handleKisokError = (
      errorDispenserName: 'hp1' | 'bd1' | 'bd2' | 'bd3',
    ) => {
      if (errorDetectionStatusRef.current[errorDispenserName] === 0) {
        errorDetectionStatusRef.current[errorDispenserName]++;
        fetchKioskStatus('ERROR');
      }
    };

    if (hp1Error && errorDetectionStatusRef.current.hp1 === 0) {
      handleKisokError('hp1');
    } else if (bd1Error && errorDetectionStatusRef.current.bd1 === 0) {
      handleKisokError('bd1');
    } else if (bd2Error && errorDetectionStatusRef.current.bd2 === 0) {
      handleKisokError('bd2');
    } else if (bd3Error && errorDetectionStatusRef.current.bd3 === 0) {
      handleKisokError('bd3');
    } else {
      if (currentCMDRef.current === 'EMIT') {
        setIsLoading(false);
        setAdminLoginInfo({
          isManager: null,
          controlCode: null,
        });
        setTimeout(() => {
          goRefundComplete();
        }, 500);
      } else if (currentCMDRef.current === 'RESET') {
        currentCMDRef.current = null;
        setIsLoading(false);
        setModalInfo({
          title: '키오스크 상태가 정상입니다.',
          description: '',
          icon: 'CHECK',
          btnCallback: () => {
            log(
              `리셋 성공:: - 키오스크 이름: ${currentLoginInfoRef?.current?.name}, controlCode: ${currentLoginInfoRef?.current?.controlCode}, - passportNumber: ${withdrawalInfoRef?.current?.passportNumber}`,
            );
            setAdminLoginInfo({
              isManager: null,
              controlCode: null,
            });
            navigate('/promotion');
          },
        });
      }
    }
  };

  const handleDefaultResponse = (data: Array<number>) => {
    const statusByte1 = to8BitBinary(data[0]);
    const statusByte2 = to8BitBinary(data[1]);
    const countArr = data.slice(2);

    const isEmitting = Boolean(statusByte1[4]);
    if (isEmitting) {
      console.log('isEmitting', isEmitting);
      return;
    }
    //* 방출 중이면 아래 로직 실행X
    log(`응답 수신(0x13 기본 조회) : ${data}`);
    const hp1Error = Boolean(statusByte1[1]);
    const bd1Error = Boolean(statusByte2[7]);
    const bd2Error = Boolean(statusByte2[6]);
    const bd3Error = Boolean(statusByte2[5]);

    /* 리셋(0x10) 요청시 자동 기본 조회(0x13) 응답 처리*/
    //- 리셋했을 때 에러 없으면: 자동 기본 조회 응답 1회만 옴
    //- 리셋했을 때 여전히 에러있으면: 자동 기본 조회 응답 2회 옴
    if (currentCMDRef.current === 'RESET') {
      if (!handleResetResponse(hp1Error, bd1Error, bd2Error, bd3Error)) {
        return;
      }
    }
    // console.log('currentCMDRef Before: ', currentCMDRef.current);
    /* 방출(0x12) 요청시 자동 기본 조회(0x13) 응답 처리*/
    // - 방출 성공시: 자동 기본 조회 응답 1회만 옴
    // - 방출 실패시: 자동 기본 조회 응답 2회옴
    if (currentCMDRef.current === 'EMIT') {
      if (!handleEmitResponse()) {
        return;
      }
    }

    updateKioskStatus(countArr, hp1Error, bd1Error, bd2Error, bd3Error);
    handleKioskErrors(hp1Error, bd1Error, bd2Error, bd3Error);
  };
  const logErrorResponse = (
    RecvComBuffer: Array<number>,
    newKioskStatus: any,
  ) => {
    log(`에러 발생:: - 응답 수신(0x19 에러 조회): ${RecvComBuffer}, - 키오스크 이름: ${
      currentLoginInfoRef.current.name
    }, - passportNumber: ${withdrawalInfoRef.current.passportNumber}, 
    \n- 에러 정보: ${JSON.stringify(newKioskStatus)}
    \n- 방출 정보: bd1Count:${
      refundInfoRef.current.bd1Count -
      currentMoneyNotWithdrawnRef.current.bd1Count
    }, bd2Count:${
      refundInfoRef.current.bd2Count -
      currentMoneyNotWithdrawnRef.current.bd2Count
    }, bd3Count:${
      refundInfoRef.current.bd3Count -
      currentMoneyNotWithdrawnRef.current.bd3Count
    }, hp1Count:${
      refundInfoRef.current.hp1Count -
      currentMoneyNotWithdrawnRef.current.hp1Count
    }, 
    \n- 미방출 금액: bd1Count:${
      currentMoneyNotWithdrawnRef.current.bd1Count
    }, bd2Count:${currentMoneyNotWithdrawnRef.current.bd2Count}, bd3Count:${
      currentMoneyNotWithdrawnRef.current.bd3Count
    }, hp1Count:${currentMoneyNotWithdrawnRef.current.hp1Count}`);
  };

  const updateErrorDetectionStatus = (
    bd1ErrorCode: number | number[],
    bd2ErrorCode: number | number[],
    bd3ErrorCode: number | number[],
  ) => {
    if (bd1ErrorCode === Errors.ERR_EMPTY) {
      errorDetectionStatusRef.current.bd1++;
    }
    if (bd2ErrorCode === Errors.ERR_EMPTY) {
      errorDetectionStatusRef.current.bd2++;
    }
    if (bd3ErrorCode === Errors.ERR_EMPTY) {
      errorDetectionStatusRef.current.bd3++;
    }
  };

  const updateRefundInfo = (newInfo: Partial<CountInfo>) => {
    setRefundInfo({
      ...refundInfoRef.current,
      ...newInfo,
    });
  };

  const handleHp1Error = (bd2Error: boolean, bd3Error: boolean) => {
    errorDetectionStatusRef.current.hp1++;
    if (emissionKioskStatusRef.current.bd2Status && !bd2Error) {
      updateRefundInfo({
        hp1Count:
          refundInfoRef.current.hp1Count -
          currentMoneyNotWithdrawnRef.current.hp1Count,
        bd2Count: refundInfoRef.current.bd2Count + 1,
      });
      emitBills({
        hp1Count: 0,
        bd1Count: 0,
        bd2Count: 1,
        bd3Count: 0,
      });
    } else if (emissionKioskStatusRef.current.bd3Status && !bd3Error) {
      updateRefundInfo({
        hp1Count:
          refundInfoRef.current.hp1Count -
          currentMoneyNotWithdrawnRef.current.hp1Count,
        bd3Count: refundInfoRef.current.bd3Count + 1,
      });
      emitBills({
        hp1Count: 0,
        bd1Count: 0,
        bd2Count: 0,
        bd3Count: 1,
      });
    }
  };

  const handleBd1Error = (bd2Error: boolean, bd3Error: boolean) => {
    errorDetectionStatusRef.current.bd1++;

    if (currentMoneyNotWithdrawnRef.current.bd1Count > 3) {
      if (emissionKioskStatusRef.current.bd2Status && !bd2Error) {
        updateRefundInfo({
          bd1Count:
            refundInfoRef.current.bd1Count -
            currentMoneyNotWithdrawnRef.current.bd1Count,
          bd2Count: refundInfoRef.current.bd2Count + 1,
        });
        emitBills({
          hp1Count: 0,
          bd1Count: 0,
          bd2Count: 1,
          bd3Count: 0,
        });
      } else if (emissionKioskStatusRef.current.bd3Status && !bd3Error) {
        updateRefundInfo({
          bd1Count:
            refundInfoRef.current.bd1Count -
            currentMoneyNotWithdrawnRef.current.bd1Count,
          bd3Count: refundInfoRef.current.bd3Count + 1,
        });
        emitBills({
          hp1Count: 0,
          bd1Count: 0,
          bd2Count: 0,
          bd3Count: 1,
        });
      }
    } else {
      //질문: 500원짜리 환급?
      const won500Count = Math.floor(
        (currentMoneyNotWithdrawnRef.current.bd1Count * 1000) / 500,
      );
      updateRefundInfo({
        bd1Count:
          refundInfoRef.current.bd1Count -
          currentMoneyNotWithdrawnRef.current.bd1Count,
        hp1Count: won500Count,
      });
      emitBills({
        hp1Count: won500Count,
        bd1Count: 0,
        bd2Count: 0,
        bd3Count: 0,
      });
    }
  };

  const handleBd3Error = () => {
    errorDetectionStatusRef.current.bd3++;
    const updatedRefundInfo = {
      ...refundInfoRef.current,
      bd2Count:
        refundInfoRef.current.bd2Count +
        currentMoneyNotWithdrawnRef.current.bd3Count,
      bd3Count:
        refundInfoRef.current.bd3Count -
        currentMoneyNotWithdrawnRef.current.bd3Count,
    };
    updateRefundInfo(updatedRefundInfo);
    emitBills({
      ...updatedRefundInfo,
      bd3Count: 0,
    });
  };

  const handleBd2Error = () => {
    errorDetectionStatusRef.current.bd2++;
    updateRefundInfo({
      hp1Count: refundInfoRef.current.hp1Count,
      bd1Count: refundInfoRef.current.bd1Count,
      bd2Count:
        refundInfoRef.current.bd2Count -
        currentMoneyNotWithdrawnRef.current.bd2Count,
      bd3Count:
        refundInfoRef.current.bd3Count +
        currentMoneyNotWithdrawnRef.current.bd2Count,
    });
    emitBills({
      hp1Count: refundInfoRef.current.hp1Count,
      bd1Count: refundInfoRef.current.bd1Count,
      bd2Count: 0,
      bd3Count:
        refundInfoRef.current.bd3Count +
        currentMoneyNotWithdrawnRef.current.bd2Count,
    });
  };

  const completeRefundWithErrors = () => {
    updateRefundInfo({
      hp1Count:
        refundInfoRef.current.hp1Count -
        currentMoneyNotWithdrawnRef.current.hp1Count,
      bd1Count:
        refundInfoRef.current.bd1Count -
        currentMoneyNotWithdrawnRef.current.bd1Count,
      bd2Count:
        refundInfoRef.current.bd2Count -
        currentMoneyNotWithdrawnRef.current.bd2Count,
      bd3Count:
        refundInfoRef.current.bd3Count -
        currentMoneyNotWithdrawnRef.current.bd3Count,
    });
    goRefundComplete(true);
    setIsLoading(false);
  };

  const handleErrorResponse = (data: Array<number>) => {
    emitDefaultInquiryCountRef.current = 0;
    const statusByte1 = to8BitBinary(data[0]);
    const statusByte2 = to8BitBinary(data[1]);
    const [bd1ErrorCode, bd2ErrorCode, bd3ErrorCode] = data.slice(2, 5);

    const hp1Error = Boolean(statusByte1[1]);
    const bd1Error = Boolean(statusByte2[7]);
    const bd2Error = Boolean(statusByte2[6]);
    const bd3Error = Boolean(statusByte2[5]);

    const newKioskStatus = {
      ...currentKioskStatusRef.current,
      bd1Error,
      bd2Error,
      bd3Error,
      hp1Error,
      bd1ErrorCode,
      bd2ErrorCode,
      bd3ErrorCode,
    };
    logErrorResponse(data, newKioskStatus);

    setKioskStatus(newKioskStatus);
    currentKioskStatusRef.current = newKioskStatus;
    mutate({
      type: 'WITHDRAWAL',
      controlCode: currentLoginInfoRef.current.controlCode,
      ...newKioskStatus,
    });

    const isNoMoneyNotWithdrawn =
      currentMoneyNotWithdrawnRef.current.bd1Count === 0 &&
      currentMoneyNotWithdrawnRef.current.bd2Count === 0 &&
      currentMoneyNotWithdrawnRef.current.bd3Count === 0 &&
      currentMoneyNotWithdrawnRef.current.hp1Count === 0;

    const isLackOfCashError =
      bd1ErrorCode === Errors.ERR_EMPTY ||
      bd2ErrorCode === Errors.ERR_EMPTY ||
      bd3ErrorCode === Errors.ERR_EMPTY;

    if (isLackOfCashError) {
      if (isNoMoneyNotWithdrawn) {
        updateErrorDetectionStatus(bd1ErrorCode, bd2ErrorCode, bd3ErrorCode);
        setIsLoading(false);
        return;
      }
    }

    if (hp1Error && errorDetectionStatusRef.current.hp1 === 1) {
      handleHp1Error(bd2Error, bd3Error);
    } else if (bd1Error && errorDetectionStatusRef.current.bd1 === 1) {
      handleBd1Error(bd2Error, bd3Error);
    } else if (
      bd3Error &&
      errorDetectionStatusRef.current.bd3 === 1 &&
      !bd2Error
    ) {
      handleBd3Error();
    } else if (
      bd2Error &&
      errorDetectionStatusRef.current.bd2 === 1 &&
      !bd3Error
    ) {
      handleBd2Error();
    }
    if (bd2Error && bd3Error) {
      completeRefundWithErrors();
    }
  };

  const handleNonSTXStartByte = (startByte: number) => {
    const ACK = 0x11;
    const NAK = 0xee;
    if (startByte === ACK) {
      log('ACK');
    } else if (startByte === NAK) {
      log('NAK');
    } else {
      log(`시리얼 통신 에러:: 알 수 없는 패킷 헤더 ${startByte}`);
    }
  };

  const readResponse = async () => {
    if (!port) return;
    //@ts-ignore
    const reader = port.readable.getReader();
    log(`리더기 생성 완료: ${reader}`);
    let buffer: number[] = [];
    emitDefaultInquiryCountRef.current = 0;

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          log('리더기 상태: DONE');
          await reader.releaseLock();
          break;
        }

        buffer = [...buffer, ...value];
        console.log('buffer::', buffer);

        while (buffer.length >= 19) {
          const startByte = buffer[0];
          if (startByte !== STX) {
            handleNonSTXStartByte(startByte);
            buffer = buffer.slice(1);
            continue;
          }

          //STX 통과되어야 들어오는 로직
          const packet = buffer.slice(0, 19);

          const chksum = calculateFCC(packet.slice(0, -1));
          const FCC = packet[packet.length - 1];
          const version = packet[packet.length - 2];
          const isCorrectVersion = [0x13, 0x15].includes(version);

          if (chksum !== FCC || !isCorrectVersion) {
            log('시리얼 통신 에러:: 유효하지 않은 형식의 응답 수신');
            buffer = [];
            continue;
          }

          // const BC = packet[1];
          const CMD = packet[2];
          const data = packet.slice(3, -1);

          switch (CMD) {
            case Commands.RES_STATUS_CODE:
              handleDefaultResponse(data);
              break;
            case Commands.RES_EMITTER_ERROR_CODE:
              handleErrorResponse(data);
              break;
            default:
              log(`알 수 없는 CMD 에러 : ${CMD}`);
              buffer = [];
              continue;
          }
          buffer = buffer.slice(19); // 처리된 패킷을 버퍼에서 제거
        }
      }
      return 0;
    } catch (err) {
      log(`시리얼 통신 에러:: 응답 수신 에러 ${err}`);
    }
  };
  useEffect(() => {
    if (port) {
      setModalInfo({
        title: '키오스크 연결이 완료되었습니다.',
        description: '',
        icon: 'CHECK',
        btnCallback: () => {
          readResponse();
        },
      });
    } else {
      openInitializationModal();
    }
  }, [port]);

  return {
    resetKiosk,
    readResponse,
    emitBills,
    initialize,
    isLoading: isLoading || refundCompleteLoading,
  };
}

export default useSerialCommunication;
